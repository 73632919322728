var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticStyle: { "padding-top": "1rem" } }, [
    _c(
      "div",
      { staticClass: "reports_filter px-5 mb-8" },
      [
        _c(
          "v-row",
          [
            _c(
              "v-col",
              { staticClass: "mr-4", attrs: { cols: "2" } },
              [
                _c("z-input-date", {
                  staticClass: "mr-1",
                  attrs: {
                    label: "De:",
                    format: "pt-BR",
                    type: "date",
                    hideDetails: true,
                  },
                  model: {
                    value: _vm.filter.startsAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "startsAt", $$v)
                    },
                    expression: "filter.startsAt",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "mr-4", attrs: { cols: "2" } },
              [
                _c("z-input-date", {
                  staticClass: "mr-1",
                  attrs: {
                    label: "Até:",
                    format: "pt-BR",
                    type: "date",
                    hideDetails: true,
                  },
                  model: {
                    value: _vm.filter.endsAt,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "endsAt", $$v)
                    },
                    expression: "filter.endsAt",
                  },
                }),
              ],
              1
            ),
            _c(
              "v-col",
              { staticClass: "mr-4", attrs: { cols: "2" } },
              [
                _c("z-btn", {
                  staticStyle: {
                    "margin-right": "8px",
                    "margin-bottom": "5px",
                  },
                  attrs: { text: "Filtrar", primary: "" },
                  on: {
                    click: function ($event) {
                      return _vm.filterReports()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    !_vm.isLoading
      ? _c("div", { staticClass: "wrapper__card mx-5" }, [
          _c(
            "div",
            { staticClass: "wrapper__card__body" },
            [
              _c(
                "v-row",
                { attrs: { justify: "space-around" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              { staticClass: "icon_card_report" },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$send")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("TOTAL DE ENVIOS")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgb(246, 189, 2)",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.totalSent) + " (100%)")]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgba(255, 143, 40, 1)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$drafts")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("TOTAL DE ABERTURAS")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgba(255, 143, 40, 1)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalOpens) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalSent !== 0
                                              ? (
                                                  (_vm.totalOpens /
                                                    _vm.totalSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "mx-3 py-2",
                          attrs: { elevation: "2", shaped: "" },
                        },
                        [
                          _c("v-row", { attrs: { justify: "center" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "icon_card_report",
                                staticStyle: {
                                  "background-color": "rgba(73, 203, 140, 1)",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-3 mt-3",
                                    attrs: { color: "white" },
                                  },
                                  [_vm._v("$arrow_back")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "ml-5" },
                              [
                                _c("v-row", { staticClass: "mb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "0.85rem",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("TOTAL DE CLIQUES")]
                                  ),
                                ]),
                                _c("v-row", { staticClass: "mt-0" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "rgba(73, 203, 140, 1)",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.totalClicks) +
                                          " (" +
                                          _vm._s(
                                            _vm.totalSent !== 0
                                              ? (
                                                  (_vm.totalClicks /
                                                    _vm.totalSent) *
                                                  100
                                                ).toFixed(2)
                                              : 0
                                          ) +
                                          "%)"
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticStyle: { height: "50vh !important" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-row", { staticClass: "ml-2 mt-2" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": ".8rem",
                              "font-weight": "600",
                            },
                          },
                          [_vm._v("SEQUÊNCIA DE FUNIL")]
                        ),
                      ]),
                      _c(
                        "v-row",
                        {
                          staticClass: "mt-10",
                          staticStyle: { "margin-left": "50px" },
                        },
                        [
                          _vm.treeTest != null
                            ? _c("TreeComponent", {
                                key: _vm.keyComponent,
                                attrs: {
                                  isReportRender: true,
                                  nodes: _vm.treeTest,
                                  "database-config-id-prop":
                                    _vm.databaseConfigId,
                                },
                                on: { node_click: _vm.nodeClick },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _c("div", [
          _c(
            "div",
            { staticClass: "wrapper__card" },
            [
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [_c("z-loading")],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }